/**
 * forEach wrapper for async/await use.
 * @see https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
 * @author Sebastien Chopin
 */
export async function asyncForEach<T>(
  array: T[],
  callback: (item: T, index: number, array: T[]) => any
) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

/**
 * Copying array or objects.
 * This one safely copies deeply nested objects/arrays.
 * @see https://www.freecodecamp.org/news/how-to-clone-an-array-in-javascript-1d3183468f6a/
 * @param obj
 */
export function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Reordering list items.
 * @see https://codesandbox.io/s/k260nyxq9v
 * @param list
 * @param startIndex
 * @param endIndex
 */
export function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function zeroPad(number: number, digits: number) {
  return Array(Math.max(digits - String(number).length + 1, 0)).join(`0`) + number
}

export function createArrayFrom1toN(n: number) {
  // @ts-ignore
  return Array.from([...Array(n).keys()].map(n => n + 1))
}

/**
 * Unbiased array shuffle method (Fisher-Yates (aka Knuth) Shuffle).
 * @see https://github.com/Daplie/knuth-shuffle
 * @see http://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 */
export function shuffleArray<T>(array: T[]) {
  var currentIndex = array.length
    , temporaryValue
    , randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function duplicateArray<T>(array: T[]) {
  return [...clone(array), ...clone(array)]
}

/**
 * Generates a random integer.
 * @see https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Math/random
 */
export function random(min: number, max: number) {
  return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min)
}