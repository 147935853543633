import React, { useCallback, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { useArray } from 'react-hanger'
import gsap from 'gsap'

import * as styles from './featuresShowcase.module.scss'

import gmailLogo from '../../assets/features/gmail.png'
import googleChromeLogo from '../../assets/features/google-chrome.png'
import googleCalendarLogo from '../../assets/features/google-calendar.png'
import googleDocsLogo from '../../assets/features/google-docs.png'
import googleDriveLogo from '../../assets/features/google-drive.png'
import googleFormsLogo from '../../assets/features/google-forms.png'
import googleSheetsLogo from '../../assets/features/google-sheets.png'
import recaptchaLogo from '../../assets/features/recaptcha.png'

import outlookLogo from '../../assets/features/outlook.png'
import microsoftEdgeLogo from '../../assets/features/microsoft-edge.png'
import microsoftExcelLogo from '../../assets/features/microsoft-excel.png'
import microsoftFlowLogo from '../../assets/features/microsoft-flow.png'
import microsoftFormsLogo from '../../assets/features/microsoft-forms.png'
import microsoftOnedriveLogo from '../../assets/features/microsoft-onedrive.png'
import microsoftSharepointLogo from '../../assets/features/microsoft-sharepoint.png'
import microsoftWordLogo from '../../assets/features/microsoft-word.png'

import mongodbLogo from '../../assets/features/mongodb.png'
import mysqlLogo from '../../assets/features/mysql.png'
import sqlServerLogo from '../../assets/features/sql-server.png'
import terminalLogo from '../../assets/features/terminal.png'
import windowsExplorerLogo from '../../assets/features/windows-explorer.png'
import whatsappLogo from '../../assets/features/whatsapp.png'
import sapLogo from '../../assets/features/sap.png'

import { FeaturesIcon, FeatureIconProps } from './featuresIcon'
import { clone, duplicateArray, shuffleArray } from '../../lib/utils'
import { useOnMount } from '../../lib/hooks'
import { useWindowSize } from '../../utils/hooks'

const features: FeatureIconProps[] = [
  { name: `Gmail`, icon: gmailLogo },
  { name: `Google Chrome`, icon: googleChromeLogo },
  {
    name: `Microsoft Flow`,
    icon: microsoftFlowLogo,
    backgroundColor: `#0073ff`,
  },
  { name: `Google Calendar`, icon: googleCalendarLogo },
  {
    name: `Microsoft Forms`,
    icon: microsoftFormsLogo,
    backgroundColor: `#008372`,
  },
  { name: `Google Docs`, icon: googleDocsLogo },
  {
    name: `Microsoft Word`,
    icon: microsoftWordLogo,
    backgroundColor: `#0051a3`,
  },
  { name: `Google Drive`, icon: googleDriveLogo },
  {
    name: `Microsoft Onedrive`,
    icon: microsoftOnedriveLogo,
    backgroundColor: `#0062b1`,
  },
  { name: `Google Forms`, icon: googleFormsLogo },
  {
    name: `Microsoft Sharepoint`,
    icon: microsoftSharepointLogo,
    backgroundColor: `#0070bb`,
  },
  { name: `Google Sheets`, icon: googleSheetsLogo },
  { name: `Recaptcha`, icon: recaptchaLogo },
  { name: `Outlook`, icon: outlookLogo, backgroundColor: `#0071c7` },
  { name: `Microsoft Edge`, icon: microsoftEdgeLogo },
  {
    name: `Microsoft Excel`,
    icon: microsoftExcelLogo,
    backgroundColor: `#007338`,
  },

  { name: `Mongodb`, icon: mongodbLogo },
  { name: `MySQL`, icon: mysqlLogo, backgroundColor: `#00546d` },
  { name: `SQL Server`, icon: sqlServerLogo },
  { name: `Terminal`, icon: terminalLogo, backgroundColor: `#344a5e` },
  { name: `File Explorer`, icon: windowsExplorerLogo },
  { name: `WhatsApp`, icon: whatsappLogo, backgroundColor: `#25d366` },
  { name: `SAP`, icon: sapLogo, backgroundColor: `#003366` },
]

export const FeaturesShowcase: React.FC = () => {
  const track01Animated = useRef(false)
  const track02Animated = useRef(false)

  const size = useWindowSize()

  const featureSize = size.width | 96
  const featureMargin = size.width | 16

  const getFullFeatureSize = () => {
    return featureSize + featureMargin
  }

  const features01 = useArray<FeatureIconProps>([])
  const features02 = useArray<FeatureIconProps>([])

  const animateTrack = useCallback((track: `track-01` | `track-02`) => {
    const elems = gsap.utils.toArray(`.${track} .feature-item`)

    const duration = elems.length * 3
    const delay =
      track === `track-02` ? -(duration / elems.length / 2) : undefined

    for (const [index] of elems.entries()) {
      gsap.set(`.${track} .feature-${index}`, {
        x: () => index * getFullFeatureSize(),
        overwrite: `auto`,
      })
    }

    gsap.to(`.${track} .feature-item`, {
      duration,
      delay,
      ease: `none`,
      repeat: -1,
      x: `+=${elems.length * getFullFeatureSize()}`,
      modifiers: {
        x: gsap.utils.unitize(
          gsap.utils.wrap(
            -getFullFeatureSize(),
            elems.length * getFullFeatureSize() - getFullFeatureSize()
          ),
          `px`
        ),
      },
    })
  }, [])

  useOnMount(() => {
    const shuffled = shuffleArray(clone(features))
    const half = Math.ceil(shuffled.length / 2)
    features01.setValue(duplicateArray(shuffled.splice(0, half)))
    features02.setValue(duplicateArray(shuffled.splice(-half)))
  })

  useEffect(() => {
    if (features01.value.length > 0 && !track01Animated.current) {
      track01Animated.current = true
      animateTrack(`track-01`)
    }
    if (features02.value.length > 0 && !track02Animated.current) {
      track02Animated.current = true
      animateTrack(`track-02`)
    }
  }, [features01.value, features02.value])

  return (
    <div className={clsx(styles.animatedTracks)}>
      <div
        className={clsx(
          { [styles.featureShowcaseTrack]: true },
          'track track-01'
        )}
        style={{ height: 90 }}
      >
        {features01.value.map((feature, index) => (
          <FeaturesIcon
            key={index}
            className={`d-inline-block feature-item feature-${index}`}
            size={90}
            {...feature}
          />
        ))}
      </div>
      <div
        className={clsx(
          { [styles.featureShowcaseTrack]: true },
          'track track-02'
        )}
        style={{ height: 90 }}
      >
        {features02.value.map((feature, index) => (
          <FeaturesIcon
            key={index}
            className={`d-inline-block feature-item feature-${index}`}
            size={90}
            {...feature}
          />
        ))}
      </div>
    </div>
  )
}
