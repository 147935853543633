import React from "react"

const I18nContent = (props: Props) => {
  const parts = props.content.split(`\n`)
  return (
    <>
      {parts.length === 1 ? props.content : (
        parts.map((part, index) => (
          <>
            {part}
            {index < parts.length - 1 && (
              <>
                <br />
                <br />
              </>
            )}
          </>
        ))
      )}
    </>
  )
}

type Props = {
  content: string
}

export default I18nContent