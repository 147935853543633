import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import { PageProps } from 'gatsby'

import * as styles from '../styles/pages/index.module.scss'
import { useMediaQuery } from '../utils/hooks'

import { Layout } from '../components/layout'
import { Header } from '../components/header'
import { Button } from '../components/button'
import { SectionTitle } from '../components/sectionTItle'
import { FeaturesGridIcon } from '../components/featuresGridIcon'
import i18n from '../i18n'
import { SEO } from '../components/SEO'
import { FeaturesShowcase } from '../components/featuresShowcase'
import { AnimatedCounter } from '../components/animatedCounter/animatedCounter'
import { PartnerList } from '../components/partners'

import featuredClients from '../assets/header/featuredClients.png'
import featuredClientsMobile from '../assets/header/featuredClientsMob1.png'
import featuredClientsMobile2 from '../assets/header/featuredClientsMob2.png'
import featuredClientsMobile3 from '../assets/header/featuredClientsMob3.png'
import light from '../assets/bulbLight.png'
import terminalIcon from '../assets/overview/terminal-icon.png'
import userIcon from '../assets/overview/user-icon.png'
import partners from '../assets/partners/partners.png'
import robertyIcon from '../assets/roberty.png'
import caseUnimed from '../assets/cases/caseUnimed.png'
import caseSemar from '../assets/cases/caseSemar.png'
import caseSicoob from '../assets/testimonials/sicoob.png'
import caseOuroFino from '../assets/cases/caseOurofino.png'
import coverOuroFino from '../assets/cases/ourofinoCover.jpeg'
import coverSicoob from '../assets/cases/sicoobCover.jpeg'
import actionsSample from '../assets/gifs/actionsSample.mp4'
import actionsSections from '../assets/gifs/actionsSections.mp4'
import actionsCollab from '../assets/gifs/colabSample.mp4'
import I18nContent from '../components/i18n'

const useVideoControl = (videoRefs, isMobile) => {
  useEffect(() => {
    const playVideos = () => {
      videoRefs.forEach((ref) => {
        if (ref.current) {
          setTimeout(() => {
            ref.current.play()
            if (isMobile) {
              ref.current.setAttribute(`controls`, `true`)
              ref.current.currentTime = 0.01
            }
          }, 500)
        }
      })
    }

    const resetVideos = () => {
      videoRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.pause()
        }
      })
    }

    playVideos()

    return () => {
      resetVideos()
    }
  }, [videoRefs, isMobile])
}

const Home: React.FC<PageProps> = ({ location }) => {
  const isMobile = useMediaQuery(`580`)
  const mainVideo = useRef(null)
  const sectionVideo = useRef(null)
  const sectionCollab = useRef(null)

  useVideoControl([mainVideo, sectionVideo, sectionCollab], isMobile)

  const counter = [
    {
      count: 2,
      label: `Horas de execução`,
    },
    {
      count: 7000,
      label: `Processos automatizados em funcionamento`,
    },
    {
      count: 20,
      label: `Partners em todo o Brasil`,
    },
  ]

  const cases = [
    {
      title: `Case Ourofino`,
      description: `Projeto que prevê a redução de mais de 3500 horas de redução de trabalho anual, robôs atuando em praticamente todos os setores e departamentos, alto ganho em desempenho e conformidade. A Ourofino através do seu time interno, estabeleceu uma estrutura de governança sustentável e está acelerando a transformação de seus processos através da tecnologia de RPA.`,
      href: `https://blog.roberty.app/`,
      icon: caseOuroFino,
      image: coverOuroFino,
    },
    {
      title: `Case Sicoob Cocre`,
      description: `Com um projeto audacioso, o Sicoob Cocre é um parceiro Roberty desde 2021 e possui robôs atuando em várias frentes, tornando a experiência de seus clientes mais atrativa, rápida e dinâmica. Com isso, seus colaborados ficam focados em atividades de alto valor para a instituição.`,
      href: `https://blog.roberty.app/`,
      icon: caseSicoob,
      image: coverSicoob,
    },
    {
      title: `Case SEMAR`,
      description: `No seguimento de varejo, muitas são as atividades manuais e repetitivas, que demandam uma grande quantidade de pessoas. Na SEMAR, o Roberty se tornou um de seus colaboradores e trabalha processando todas as entradas de NFs de mercadorias.`,
      href: `https://blog.roberty.app/`,
      icon: caseSemar,
    },
    {
      title: `Case Unimed Dracena`,
      description: `De forma geral, as instituições de saúde estão investindo fortemente em tecnologia, inteligência artificial e hiperautomação. A Unimed Dracena não é diferente e já possuem diversos robôs atuando em funções estratégicas.`,
      href: `https://blog.roberty.app/`,
      icon: caseUnimed,
    },
  ]

  return (
    <Layout isLargeFooter={false} isHome location={location}>
      <SEO title="Roberty Automation - Plataforma de RPA para hiperautomaçāo de processos." />

      <div className={clsx(styles.mainHeader)}>
        <Header isWhite isHome />

        {/* Main title */}
        <div className={styles.mainTitle}>
          <h1>{i18n['home.header.mainTitle']}</h1>
          <p>{i18n['home.header.copy']}</p>
          <Button
            color="primary"
            href="/contactSales"
            title={i18n[`home.header.cta`]}
            size="large"
          >
            {i18n[`home.header.cta`]}
          </Button>
        </div>
        {/* End Main title */}

        {/* Featured Clients */}
        <div className={styles.featuredClients}>
          <p>{i18n['home.header.clients.copy']}</p>
          {!isMobile && (
            <img
              src={featuredClients}
              alt="Featured Clients"
              title="Featured Clients"
            />
          )}
          {isMobile && (
            <div>
              <img
                src={featuredClientsMobile}
                alt="Featured Clients"
                title="Featured Clients"
              />
              <img
                src={featuredClientsMobile2}
                alt="Featured Clients"
                title="Featured Clients"
              />
              <img
                src={featuredClientsMobile3}
                alt="Featured Clients"
                title="Featured Clients"
              />
            </div>
          )}
        </div>
        {/* End Featured Clients */}
      </div>

      {/* Intro Section */}
      <div className={styles.introSection}>
        <div className="container-fluid">
          <div className={styles.section}>
            <div className={styles.icons}>
              <FeaturesShowcase />
            </div>
            <div className={styles.title}>
              <h3>{i18n['home.intro.title']}</h3>
            </div>
            <div className={styles.picture}>
              <video
                controls={false}
                loop={true}
                muted={true}
                ref={mainVideo}
                preload="metadata"
              >
                <source
                  src={isMobile ? `${actionsSample}#t=0.001` : actionsSample}
                  title={`Actions Designer`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className={styles.copy}>
              <p>
                {i18n[`common.aboutRoberty`]}
                <b>{i18n[`common.RPA`]}</b>
                {i18n[`common.robotsIntelligent`]}
                <br /> <br />
                {i18n[`common.AgilSimple`]}
                {i18n[`common.robertyToolRPA`]}
                <b>{i18n[`common.LowCode`]}</b>
                {i18n[`common.useTool`]}
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className={styles.capacity}>
            <div className={styles.card}>
              <h3>{i18n[`common.robertyCapacity`]}</h3>
              <p>{i18n[`common.robertyCapacityText`]}</p>
              <Button
                color="rose"
                title={i18n[`common.demonstrationTest`]}
                size="large"
                onClick={(e) => {
                  e.preventDefault()
                  // @ts-ignore
                  Calendly.initPopupWidget({
                    url: 'https://calendly.com/roberty-app/roberty',
                    prefill: {
                      customAnswers: {
                        a3: 1,
                      },
                    },
                  })
                }}
              >
                {i18n[`common.demonstrationTest`]}
              </Button>
            </div>
            {!isMobile && (
              <div className={styles.icon}>
                <img src={light} alt="Light Bulb" title="Light Bulb" />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* End Intro Section */}

      {/* Overview Section */}
      <section className={styles.overview}>
        <div className="container-fluid">
          <div className={styles.section}>
            <div className={styles.description}>
              <img
                src={terminalIcon}
                title="Terminal icon"
                alt="Terminal icon"
              />
              <h3>{i18n['home.overview.title.designer']}</h3>
              <p>
                <I18nContent
                  content={i18n['home.overview.copy.designer']}
                />
              </p>
            </div>
            <div className={styles.videoSample}>
              <video
                controls={false}
                loop={true}
                muted={true}
                ref={sectionVideo}
                preload="metadata"
              >
                <source
                  src={
                    isMobile ? `${actionsSections}#t=0.001` : actionsSections
                  }
                  title={`Actions Designer`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className={clsx(styles.section, styles.inverter)}>
            <div className={styles.description}>
              <img src={userIcon} title="User icon" alt="User icon" />
              <h3>{i18n['home.overview.title.collaboration']}</h3>
              <p>
                <I18nContent
                  content={i18n['home.overview.copy.collaboration']}
                />
              </p>
            </div>
            <div className={clsx(styles.videoSample, styles.small)}>
              <video
                controls={false}
                loop={true}
                muted={true}
                ref={sectionCollab}
                preload="metadata"
              >
                <source
                  src={isMobile ? `${actionsCollab}#t=0.001` : actionsCollab}
                  title={`Actions Collab Designer`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <br />
          <br />
          <Button
            color="rose"
            className={styles.bigCta}
            title={i18n[`home.overview.cta.collaboration`]}
            size="large"
            onClick={(e) => {
              e.preventDefault()
              // @ts-ignore
              Calendly.initPopupWidget({
                url: 'https://calendly.com/roberty-app/roberty',
                prefill: {
                  customAnswers: {
                    a3: 1,
                  },
                },
              })
            }}
          >
            {i18n[`home.overview.cta.collaboration`]}
          </Button>
        </div>
      </section>
      {/* End Overview Section */}

      <section className={styles.showcase}>
        <div className="container">
          <div className={styles.title}>
            <SectionTitle
              title={<h1>{i18n[`common.benefitPartner`]}</h1>}
              position="left"
              color="darkBlue"
              description={i18n[`common.reasonPartnerUse`]}
            />
            <img src={partners} alt="Partners" />
          </div>
        </div>
        <div className={styles.mainFeatures}>
          <div className="container">
            <FeaturesGridIcon />
          </div>
        </div>

        <div className="container">
          <div className={styles.counter}>
            <div className={styles.icon}>
              <img src={robertyIcon} alt="Roberty logo" title="Roberty logo" />
            </div>
            {counter.map((c, key) => (
              <div className={styles.count} key={c.count}>
                <span>
                  {`+`}
                  <AnimatedCounter value={c.count} />
                  {key === 0 && (
                    <small> {i18n['home.overview.count.unit']}</small>
                  )}
                </span>
                <p>{c.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={styles.testimonials}>
        <div className={clsx('container-fluid')}>
          <div className={styles.content}>
            <div className={styles.slider}>
              <PartnerList
                onlySlider
                slidesToScroll={1}
                slidesToShow={3}
                isAutoplay
              />
            </div>
            <div className={styles.action}>
              <div className={styles.lines}>
                <p>{i18n['partnerList.partnerText']}</p>
                <Button
                  color="rose"
                  href="/contactSales"
                  title={i18n[`home.testimonials.cta`]}
                  size="large"
                >
                  {i18n[`home.testimonials.cta`]}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className={styles.cases}>
        <div className="container">
          <SectionTitle
            title={<h1>{i18n[`home.cases.title`]}</h1>}
            position="center"
            color="darkBlue"
            description={i18n[`home.cases.copy`]}
          />
        </div>
        <div className={clsx('container', styles.grid)}>
          <div className={styles.main}>
            {cases
              .filter((f) => f.image)
              .map((c) => (
                <div className={styles.content} key={c.title}>
                  <div className={styles.image}>
                    <img src={c.image} title={c.title} />
                  </div>
                  <div className={styles.description}>
                    <div className={styles.icon}>
                      <img src={c.icon} title={c.title} />
                    </div>
                    <div className={styles.title}>
                      <span>{c.title}</span>
                      <p>{c.description}</p>
                      <a
                        href={c.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18n['home.cases.href']}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.secondary}>
            {cases
              .filter((f) => !f.image)
              .map((c) => (
                <div className={styles.content} key={c.title}>
                  <div className={styles.description}>
                    <div className={styles.icon}>
                      <img src={c.icon} title={c.title} />
                    </div>
                    <div className={styles.title}>
                      <span>{c.title}</span>
                      <p>{c.description}</p>
                      <a
                        href={c.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18n['home.cases.href']}
                      </a>
                    </div>
                  </div>
                </div>
              ))}

            <div className={styles.contentLink}>
              <div className={styles.description}>
                <div className={styles.title}>
                  <p>{i18n['home.cases.knowMore']}</p>
                  <a
                    href="https://blog.roberty.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {i18n['home.cases.cta']}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className={styles.finalAction}>
        <div className="container">
          <div className={styles.content}>
            <div className={styles.title}>
              <h1>
                <span>{i18n['home.finalAction.titleSpan']} </span>
                {i18n['home.finalAction.title']}
              </h1>
              <p>{i18n['home.finalAction.copy']}</p>
            </div>
            <div className={styles.action}>
              <Button
                color="primary"
                title={i18n[`common.demonstrationTest`]}
                size="large"
                isOutline
                onClick={(e) => {
                  e.preventDefault()
                  // @ts-ignore
                  Calendly.initPopupWidget({
                    url: 'https://calendly.com/roberty-app/roberty',
                    prefill: {
                      customAnswers: {
                        a3: 1,
                      },
                    },
                  })
                }}
              >
                {i18n[`common.demonstrationTest`]}
              </Button>
              <div className={styles.divider}></div>
              <Button
                color="rose"
                href="/contactSales"
                title={i18n[`home.finalAction.cta`]}
                size="large"
              >
                {i18n[`home.finalAction.cta`]}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Home
