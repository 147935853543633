import React, { CSSProperties } from 'react'
import clsx from 'clsx'

import * as styles from './featuresShowcase.module.scss'

// Default feature icon size
const defaultSize = 150

export interface FeatureIconProps {
  icon: string
  name?: string
  backgroundColor?: string
  className?: string
  style?: CSSProperties
  size?: number
}

export const FeaturesIcon: React.FC<FeatureIconProps> = (props) => {
  return (
    <div
      className={clsx({
        [styles.featureContainer]: true,
        [props.className || '']: true,
      })}
      style={{
        ...props.style,
        width: props.size || props.style?.width || defaultSize,
        height: props.size || props.style?.height || defaultSize,
      }}
    >
      <img
        src={props.icon}
        alt={props.name}
        style={{
          width:
            ((props.size || props.style?.width || defaultSize) as number) / 2,
          height:
            ((props.size || props.style?.height || defaultSize) as number) / 2,
        }}
      />
      <div
        className={styles.featureBackground}
        style={{ backgroundColor: props.backgroundColor }}
      />
      <div className={styles.featureHexagon} />
    </div>
  )
}
